<template>
    <Button v-if="props.botaoVoltar" label="Voltar" icon="pi pi-chevron-left" class="p-button-link" @click="voltarParaPenultimoItem" />

    <div class="flex flex-row breadcrumb-container flex-wrap">
        <router-link :to="{ path: '/' }"><i class="pi pi-home icon-home"></i></router-link>
        <template v-for="crumb in rotasAnterioresItems" :key="crumb.label">
            <div class="flex align-items-center items-container">
                <i class="pi pi-chevron-right separator"></i>
                <router-link :to="crumb.to">
                    <span>{{ crumb.label }}</span>
                </router-link>
            </div>
        </template>
    </div>
</template>

<script setup>
import { useBreadcrumb } from './composables/useBreadcrumb.js';
const { rotasAnterioresItems, voltarParaPenultimoItem } = useBreadcrumb();
import { defineProps } from 'vue';

const props = defineProps({
    botaoVoltar: {
        type: Boolean,
        default: true
    }
});
</script>
<style lang="scss" scoped>
.breadcrumb-container {
    * {
        color: #495057;
        font-weight: 500;
    }

    .current-route {
        font-weight: 100;
    }

    i {
        border: none;
        padding: 10px;
        font-size: 1.3rem;
    }
}
</style>
