export function subtraiCredito(lancamento) {
    const valorAPagar = emNumero(lancamento?.emAberto);
    const valorCredito = emNumero(lancamento?.credito);
    const saldo = valorCredito - valorAPagar;

    return {
        saldo,
        saldoFormatado: saldo?.toString(),
        ...addSinalCor(saldo)
    };
}

export function emBRLComSinalCor(numero) {
    if (isNaN(+numero)) return { brl: 'R$ 0,00', sinal: '+', cor: 'green' };
    const valor = emNumero(numero);

    const brl = Math.abs(valor).toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL'
    });

    return {
        brl,
        ...addSinalCor(valor)
    };
}

export function addSinalCor(valor) {
    if (valor === 0) {
        return {
            sinal: '',
            cor: 'black'
        };
    }
    return {
        sinal: valor > 0 ? '+' : '-',
        cor: valor > 0 ? 'green' : 'red'
    };
}

export function emNumero(valor) {
    const formatado = Number(`${valor}`?.replace?.(/[^\d.,-]+/g, '') || 0);
    return isNaN(formatado) ? 0 : formatado;
}
