<script setup>
import DialogAlterarValores from './dialogs/DialogAlterarValores';
import DialogReceberReembolso from './dialogs/DialogReceberReembolso';
import AppConfirmDialog from '@/components/AppConfirmDialog.vue';
import BaseService from '@/services/BaseService';
import { ref, computed, defineProps, defineEmits } from 'vue';
import { useToast } from 'primevue/usetoast';
import { useRouter } from 'vue-router';
import SaldoExtrato from '../../components/SaldoExtrato.vue';

const emit = defineEmits(['atualizar', 'metadados', 'update:loading']);
const props = defineProps({
    value: Array,
    metadados: Object,
    loading: Boolean
});

const dialogConfirmacao = ref(false);
const loadingAppConfirmacao = ref(false);
const tituloConfirmacao = ref('');
const etapaConfirmacao = ref();

const menuItens = [
    {
        label: 'Visualizar extrato',
        icon: 'pi pi-eye',
        command: abrirExtratoPrestador
    },
    {
        label: 'Alterar valores',
        icon: 'pi pi-pencil',
        command: abrirDialogAlterarValores
    },
    {
        label: 'Solicitar reembolso dos créditos',
        icon: 'receive-money',
        command: () => {
            abrirDialogConfirmacao({
                texto: 'Deseja solicitar o reembolso dos créditos?',
                etapa: 'Solicitar reembolso dos créditos'
            });
        }
    },
    {
        label: 'Gerar crédito dos reembolsos',
        icon: 'send-money',
        command: () => {
            abrirDialogConfirmacao({
                texto: 'Deseja gerar créditos dos reembolsos?',
                etapa: 'Gerar crédito dos reembolsos'
            });
        }
    },
    {
        label: 'Reenviar e-mail reembolso',
        icon: 'pi pi-envelope',
        command: reenviarEmailReembolso
    },
    {
        label: 'Informar recebimento reembolso',
        icon: 'pi pi-verified',
        command: abrirDialogReceberReembolso
    }
];
const loadingTabela = computed({
    get() {
        return props.loading;
    },
    set(valor) {
        emit('update:loading', valor);
    }
});

const toast = useToast();

function abrirDialogConfirmacao(data) {
    tituloConfirmacao.value = data.texto;
    etapaConfirmacao.value = data.etapa;
    dialogConfirmacao.value = true;
}

async function confirmar() {
    loadingAppConfirmacao.value = true;
    if (etapaConfirmacao.value === 'Solicitar reembolso dos créditos') {
        await solicitarReembolsoDosCreditos();
    }

    if (etapaConfirmacao.value === 'Gerar crédito dos reembolsos') {
        await gerarCreditoDosReembolsos();
    }
    loadingAppConfirmacao.value = false;
    dialogConfirmacao.value = false;
}

async function solicitarReembolsoDosCreditos() {
    loadingTabela.value = true;
    try {
        const prestadorId = extratoMenu.value?.prestadorId;
        const url = `agendamento-deposito/solicar-reembolso-credito/${prestadorId}`;
        const service = new BaseService(url);
        await service.save({});

        atualizar();
    } catch (error) {
        const mensagem = error?.response?.data?.message;
        toast.add({
            severity: 'error',
            summary: mensagem || 'Erro ao tentar solicitar reembolso dos créditos! ',
            life: 3000
        });
    }
    loadingTabela.value = false;
}

async function gerarCreditoDosReembolsos() {
    loadingTabela.value = true;
    try {
        const prestadorId = extratoMenu.value?.prestadorId;
        const url = `agendamento-deposito/solicar-credito-reembolso/${prestadorId}`;
        const service = new BaseService(url);
        await service.save({});

        atualizar();
    } catch (error) {
        const mensagem = error?.response?.data?.message;
        toast.add({
            severity: 'error',
            summary: mensagem || 'Erro ao tentar gerar crédito dos reembolsos! ',
            life: 3000
        });
    }
    loadingTabela.value = false;
}

async function reenviarEmailReembolso() {
    loadingTabela.value = true;
    try {
        const prestadorId = extratoMenu.value?.prestadorId;
        const url = `agendamento-deposito/enviar-email-reembolso/${prestadorId}`;
        const service = new BaseService(url);
        await service.save({});

        atualizar();
    } catch (error) {
        const mensagem = error?.response?.data?.message;
        toast.add({
            severity: 'error',
            summary: mensagem || 'Erro ao tentar reenviar email dos reembolsos! ',
            life: 3000
        });
    }
    loadingTabela.value = false;
}

function atualizar() {
    emit('atualizar');
}

const router = useRouter();

function abrirExtratoPrestador() {
    const prestadorId = extratoMenu.value?.prestadorId;
    const prestadorName = extratoMenu.value?.nome;
    router.push(`/gestao-medica-v3/deposito-antecipado/extratos/${prestadorId}/${prestadorName}`);
}

const dialogAlterarValores = ref(null);

function abrirDialogAlterarValores() {
    dialogAlterarValores.value.abrir(extratoMenu.value?.prestadorId);
}

const dialogReceberReembolso = ref(null);

function abrirDialogReceberReembolso() {
    dialogReceberReembolso.value.abrir(extratoMenu.value);
}

const menu = ref(null);
const extratoMenu = ref(null);

function abrirMenu(event, data) {
    extratoMenu.value = data;
    menu?.value.toggle(event);
}

function formatarParaReal(valor) {
    if (!valor) return '-';
    if (!+valor) return valor;
    return (+valor.toString().replace('-', '')).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
}

function formatarDataUltimaMovimentacao(data) {
    if (!data) return '-';

    const dateObj = new Date(data);
    if (isNaN(dateObj.getTime())) return '-';

    const day = String(dateObj.getDate()).padStart(2, '0');
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const year = dateObj.getFullYear();

    const hours = String(dateObj.getHours()).padStart(2, '0');
    const minutes = String(dateObj.getMinutes()).padStart(2, '0');
    return `${day}/${month}/${year} ${hours}:${minutes}`;
}
</script>
<template>
    <div>
        <DataTable :loading="loading" :value="value" class="p-0" style="font-family: 'Inter UI', sans-serif" @sort="$emit('metadados', $event)">
            <Column field="nome" header="Prestador">
                <template #body="{ data }">
                    <div class="flex flex-column">
                        <div class="font-medium">
                            {{ data.nome }}
                        </div>
                        <div class="text-500">
                            {{ data.cnpj }}
                        </div>
                    </div>
                </template>
            </Column>
            <Column field="ultimaDataAtualizacao" sortable header="Última movimentação">
                <template #body="{ data }">
                    {{ formatarDataUltimaMovimentacao(data.ultima_atualizacao) }}
                </template>
            </Column>
            <Column field="reembolso" header="Reembolso">
                <template #body="{ data }">
                    {{ formatarParaReal(data.reembolso) }}
                </template>
            </Column>
            <Column field="credito" header="Crédito">
                <template #body="{ data }">
                    {{ formatarParaReal(data.credito) }}
                </template>
            </Column>
            <Column field="emAberto" header="Em aberto">
                <template #body="{ data }">
                    {{ formatarParaReal(data.emAberto) }}
                </template>
            </Column>
            <Column header="Saldo">
                <template #body="{ data }">
                    <SaldoExtrato :data="data" />
                </template>
            </Column>
            <Column field="menu" style="width: 50px">
                <template #body="{ data }">
                    <Button icon="pi pi-ellipsis-v" class="p-button-text p-button-primary" @click="abrirMenu($event, data)" />
                </template>
            </Column>
        </DataTable>
        <Paginator
            :rows="metadados?.limit"
            :totalRecords="metadados?.total"
            currentPageReportTemplate="Página {currentPage} de {totalPages}"
            template="PrevPageLink PageLinks NextPageLink CurrentPageReport"
            @page="$emit('metadados', $event)"
        />
        <Menu ref="menu" :model="menuItens" class="w-20rem" popup>
            <template #itemicon="{ item }">
                <div class="mr-2">
                    <Image
                        v-if="item.icon === 'receive-money'"
                        src="/layout/images/extratos/receive-money.svg"
                        alt="Recebimento de dinheiro"
                        style="margin-left: -2px"
                    />
                    <Image
                        v-else-if="item.icon === 'send-money'"
                        src="/layout/images/extratos/send-money.svg"
                        alt="Envio de dinheiro"
                        style="margin-left: -2px"
                    />
                    <i v-else :class="item.icon" class="text-900" />
                </div>
            </template>
        </Menu>
        <DialogAlterarValores ref="dialogAlterarValores" @atualizar="atualizar()" />
        <DialogReceberReembolso ref="dialogReceberReembolso" @atualizar="atualizar()" />
        <AppConfirmDialog
            v-model:visible="dialogConfirmacao"
            :loading="loadingAppConfirmacao"
            :title="tituloConfirmacao"
            @onConfirm="confirmar"
            @onClose="dialogConfirmacao = false"
        />
    </div>
</template>
