<script setup>
import { computed, defineProps } from 'vue';
import { emBRLComSinalCor, subtraiCredito } from './utils/subtraiCredito';

const props = defineProps({
    data: Object
});
const saldo = computed(() => {
    const saldo = subtraiCredito(props.data).saldo;
    return saldo !== 0 ? emBRLComSinalCor(`${saldo}`) : { brl: '-', cor: 'black' };
});
</script>
<template>
    <span :style="{ color: saldo.cor }">
        {{ saldo.sinal }}
        {{ saldo.brl }}
    </span>
</template>
