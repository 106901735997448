<script setup>
import DialogAlterarValores from './dialogs/DialogAlterarValores';
import BaseService from '@/services/BaseService';
import { ref, computed, defineEmits, defineProps, defineExpose } from 'vue';
import { useToast } from 'primevue/usetoast';
import { emBRLComSinalCor, subtraiCredito } from '../../components/utils/subtraiCredito';

const emit = defineEmits(['atualizar']);
const props = defineProps({
    prestadorId: Number
});

const loading = ref(false);
const toast = useToast();
const resumoDepositos = ref({});

async function buscarResumoDepositos() {
    loading.value = true;
    try {
        const url = 'agendamento-deposito/listar-extrato-prestadores';
        const service = new BaseService(url);
        const { data } = await service.findAll({
            filtrosExtras: { id: props.prestadorId }
        });

        resumoDepositos.value = data?.items[0];
    } catch (error) {
        const mensagem = error?.response?.data?.message;
        toast.add({
            severity: 'error',
            summary: mensagem || 'Erro ao buscar o resumo dos depósitos! ',
            life: 3000
        });
    }
    loading.value = false;
}

function atualizar() {
    emit('atualizar');
}

const balancete = computed(() => {
    const emAberto = formatarParaReal(resumoDepositos.value?.emAberto, false);
    const credito = formatarParaReal(resumoDepositos.value?.credito);
    const reembolso = formatarParaReal(resumoDepositos.value?.reembolso);

    const { saldoFormatado, sinal } = subtraiCredito(resumoDepositos.value);
    const valorSaldo = formatarParaReal(saldoFormatado, false);
    return {
        emAberto: `- ${emAberto}`,
        credito,
        reembolso,
        saldo: {
            sinal,
            valor: valorSaldo
        }
    };
});

function formatarParaReal(valor, sinal = true) {
    if (!valor || isNaN(+valor)) return 'R$ 0,00';

    const { brl, sinal: brlSinal } = emBRLComSinalCor(valor);
    return sinal ? `${brlSinal} ${brl}` : brl;
}

const dialogAlterarValores = ref(null);

function abrirDialogAlterarValores() {
    dialogAlterarValores.value.abrir(props.prestadorId);
}

buscarResumoDepositos();
defineExpose({ buscarResumoDepositos });
</script>
<template>
    <div class="mt-5 border-1 border-200 border-round-sm" style="height: calc(100% - 24px)">
        <div class="w-full px-3 py-2 surface-100 font-medium text-700">Resumo</div>
        <div class="flex flex-column justify-content-end gap-3 p-4" style="height: calc(100% - 24px)">
            <div class="flex justify-content-between text-700" style="font-size: 12px">
                <div>Em aberto</div>
                <div>{{ balancete.emAberto }}</div>
            </div>
            <div class="flex justify-content-between text-700" style="font-size: 12px">
                <div>Credito</div>
                <div>{{ balancete.credito }}</div>
            </div>
            <div class="border-top-1 border-300 my-2" />
            <div class="flex justify-content-between text-600">
                <div class="font-bold" style="font-size: 14px">Saldo</div>
                <div
                    :class="{
                        'text-green-600': resumoDepositos?.saldo?.includes('+'),
                        ' p-error': resumoDepositos?.saldo?.includes('-')
                    }"
                    style="font-size: 14px"
                >
                    {{ balancete.saldo.sinal }} {{ balancete.saldo.valor }}
                </div>
            </div>
            <Button class="p-button-outlined my-1" label="Alterar Valores" @click="abrirDialogAlterarValores" />
            <div class="flex justify-content-between text-700" style="font-size: 12px">
                <div>Reembolso</div>
                <div>{{ balancete.reembolso }}</div>
            </div>
        </div>
        <DialogAlterarValores ref="dialogAlterarValores" @atualizar="atualizar" />
    </div>
</template>
