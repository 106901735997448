import { RouterView } from 'vue-router';
const QuestionarioSESMTList = () => import('@/pages/questionarios-sesmt/Index.vue');
const QuestionarioSESMTForm = () => import('@/pages/questionarios-sesmt/Form.vue');

export const QuestionáriosSESMT = {
    path: 'questionarios-sesmt',
    name: 'Questionários SESMT',
    redirect: '/questionarios-sesmt/list',
    component: RouterView,
    children: [
        {
            path: 'list',
            name: 'Listagem de Questionários SESMT',
            meta: {
                permission: 'gestaosesmt_cadastros_questionario_sesmt:view',
                breadcrumbLabel: 'Questionários',
                rotasAnteriores: [
                    {
                        label: 'Questionários SESMT',
                        to: '/questionarios-sesmt/list'
                    }
                ]
            },
            component: QuestionarioSESMTList
        },
        {
            path: 'new',
            name: 'Novo Questionários SESMT',
            meta: {
                permission: 'gestaosesmt_cadastros_questionario_sesmt:criar',
                breadcrumbLabel: 'Adicionar questionário SESMT',
                rotasAnteriores: [
                    {
                        label: 'Questionários SESMT',
                        to: '/questionarios-sesmt/list'
                    },
                    {
                        label: 'Criar Questionário ',
                        to: '/questionarios-sesmt/new'
                    }
                ]
            },
            component: QuestionarioSESMTForm
        },
        {
            path: ':id',
            name: 'Editar Questionários SESMT',
            meta: {
                permission: 'gestaosesmt_cadastros_questionario_sesmt:editar',
                breadcrumbLabel: 'Editar questionário SESMT',
                rotasAnteriores: [
                    {
                        label: 'Questionários SESMT',
                        to: '/questionarios-sesmt/list'
                    },
                    {
                        label: 'Editar Questionário ',
                        to: '/questionarios-sesmt/:id'
                    }
                ]
            },
            component: QuestionarioSESMTForm
        }
    ]
};
