<script setup>
import DropDownGrupo from '@/pages/grupos/components/dropdown-grupos.vue';
import DropdownCliente from '@/pages/clientes/components/DropdownCliente.vue';
import DropdownUnidade from '@/pages/unidades/components/DropdownUnidade.vue';
import DropdownPrestador from '../DropdownPrestador.vue';
import { ref, defineExpose, defineProps, defineEmits, watch } from 'vue';
import { useToast } from 'primevue/usetoast';

const emit = defineEmits(['update:filtrosExtras', 'atualizar']);
const props = defineProps({
    filtrosExtras: Object
});

const toast = useToast();

const loading = ref(false);
const visivel = ref(false);
const filtros = ref({
    grupo: null,
    cliente: null,
    unidade: null,
    prestador: null,
    dataAgendamento: null,
    dataPagamento: null,
    pendentesSemDataAgendamento: null,
    codigo: null
});

watch(filtros, atualizarFiltros, { deep: true });
watch(() => props.filtrosExtras, limparFiltros);

function atualizarFiltros(filtros) {
    emit('update:filtrosExtras', {
        grupoId: filtros.grupo?.id,
        clienteId: filtros.cliente?.id,
        unidadeId: filtros.unidade?.id,
        prestadorId: filtros.prestador?.id,
        dataAgendamento: filtros.dataAgendamento || undefined,
        dataPagamento: filtros.dataPagamento || undefined,
        pendentesSemDataAgendamento: filtros.pendentesSemDataAgendamento || undefined,
        codigo: filtros.codigo || undefined
    });
}

function limparFiltros(filtrosExtras) {
    const filtrosEstaoLimpos = !Object.values(filtros.value).some(Boolean);
    const filtrosExtrasEstaoLimpos = !Object.values(filtrosExtras).some(Boolean);
    if (!filtrosEstaoLimpos && filtrosExtrasEstaoLimpos) {
        filtros.value = {
            grupo: null,
            cliente: null,
            unidade: null,
            prestador: null,
            dataAgendamento: null,
            dataPagamento: null,
            pendentesSemDataAgendamento: null,
            codigo: null
        };
    }
}

function filtrar() {
    const quantidadeDatasAgendamentos = 2;
    if (filtros.value.dataAgendamento?.length === quantidadeDatasAgendamentos && filtros.value.dataAgendamento[1] === null) {
        toast.add({
            severity: 'error',
            summary: 'É obrigatório selecionar um intervalo de datas',
            life: 3000
        });
        return;
    }
    emit('atualizar');
    fechar();
}

function abrir() {
    visivel.value = true;
}

function fechar() {
    visivel.value = false;
}

defineExpose({
    abrir
});
</script>

<template>
    <Dialog :visible="visivel" modal style="width: 644px; max-width: 90%" @update:visible="fechar()">
        <AppLoadingWrapper v-if="loading" />
        <template #header>
            <h4>Filtrar por</h4>
        </template>
        <div class="grid">
            <div class="col-6">
                <div class="text-gray-600">Grupo</div>
                <DropDownGrupo v-model="filtros.grupo" class="w-full h-3rem" placeholder="Selecione" />
            </div>
            <div class="col-6">
                <div class="text-gray-600">Cliente</div>
                <DropdownCliente
                    v-model="filtros.cliente"
                    class="w-full"
                    option-value="id"
                    placeholder="Selecione"
                    :filtros-extras="{ ativo: true, grupoId: filtros?.grupo?.id }"
                />
            </div>
            <div class="col-6">
                <div class="text-gray-600">Unidade</div>
                <DropdownUnidade
                    v-model="filtros.unidade"
                    :disabled="!filtros.cliente"
                    :filtrosExtras="{ customerId: filtros.cliente?.id }"
                    class="w-full"
                    placeholder="Selecione"
                />
            </div>
            <div class="col-6">
                <div class="text-gray-600">Prestador/CNPJ</div>
                <DropdownPrestador v-model="filtros.prestador" autoLoad class="w-full" option-label="name" placeholder="Selecione" />
            </div>
            <div class="col-6">
                <div class="text-gray-600">Código de pagamento</div>
                <InputText v-model="filtros.codigo" class="w-full" placeholder="Código" />
            </div>
            <div class="col-6">
                <div for="dataAgendamento" class="text-gray-600">Data do agendamento</div>
                <Calendar
                    id="dataAgendamento"
                    v-model="filtros.dataAgendamento"
                    :disabledDays="[0, 6]"
                    class="w-full"
                    dateFormat="dd/mm/yy"
                    hideOnRangeSelection
                    selectionMode="range"
                    showButtonBar
                    showIcon
                />
            </div>
            <div class="col-6">
                <div for="dataPagamento" class="text-gray-600">Data de pagamento</div>
                <Calendar
                    id="dataPagamento"
                    v-model="filtros.dataPagamento"
                    :disabledDays="[0, 6]"
                    class="w-full"
                    dateFormat="dd/mm/yy"
                    hideOnRangeSelection
                    selectionMode="range"
                    showButtonBar
                    showIcon
                />
            </div>
        </div>
        <template #footer>
            <Button class="p-button p-button-text" label="Cancelar" @click="fechar" />
            <Button class="p-button h-full m-0" label="Filtrar" @click="filtrar" />
        </template>
    </Dialog>
</template>
