<template>
    <div>
        <div class="flex flex-row justify-content-between align-items-end p-5 pb-0">
            <div>
                <AppBreadcrumb :botaoVoltar="botaoVoltar" />
                <div class="flex">
                    <AppInfoManual :tooltip="props.tooltip" :nomeTelaDoManual="props.nomeTelaDoManual" />
                    <h3 class="m-0">{{ titulo }}</h3>
                    <TourComponent :tourName="nomeTelaDoManual" />
                </div>
            </div>
            <slot name="extraContent"></slot>
        </div>
    </div>
</template>

<script setup>
import { defineProps } from 'vue';
import AppInfoManual from '../../../components/AppInfoManual.vue';
import TourComponent from '../../../components/TourComponent.vue';
import { useBreadcrumb } from '../breadcrumb/composables/useBreadcrumb.js';
const { titulo } = useBreadcrumb();
const props = defineProps({
    tooltip: {
        type: String,
        default: ''
    },
    nomeTelaDoManual: {
        type: String,
        default: ''
    },
    botaoVoltar: {
        type: Boolean,
        default: true
    }
});
</script>
